<template>
  <div>
    <div>
      <p v-html="$t('onboarding.classification.documents.title')"></p>
      <p v-html="$t('onboarding.classification.documents.description')"></p>
      <div v-if="loading" class="d-flex justify-center mt-10">
        <Spinner />
      </div>
      <div v-else-if="securities">
        <v-checkbox
          v-for="(option, index) in securities"
          :key="index"
          :label="$t(`onboarding.investmentSurvey.products.${option}`)"
          color="white"
          :input-value="true"
          :disabled="true"
        ></v-checkbox>
      </div>
      <p class="mt-3" v-html="$t('onboarding.classification.documents.intro')"></p>
      <p class="mt-5" v-html="$t('onboarding.classification.documents.introDocuments')"></p>
      <SignDocumentTemplate
        documentKey="consent_declaration_prok"
        class="mt-5"
        maxWidth="auto"
        @accepted="setFormValidity($event)"
      />
      <p class="mt-5" v-html="$t('onboarding.classification.documents.footer')"></p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import { get as getUser } from '@/api/users';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'Documents',
  components: {
    SignDocumentTemplate: lazyLoad('components/Documents/SignDocumentTemplate'),
    Spinner,
  },
  props: {
    settings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      checked: null,
      loading: true,
      securities: null,
    };
  },
  computed: {
    ...mapGetters('settings', [
      'classification',
    ]),
  },
  watch: {
    checked(newVal) {
      this.setFormValidity(newVal);
    },
  },
  async created() {
    this.setFormValidity(false);
    if (this.settings) {
      this.securities = this.classification.investorClassification.professionalSecurities;
    } else {
      try {
        const {
          classification: {
            investorClassification,
          },
        } = await getUser();
        if (investorClassification.investorClassification === 'Professional') {
          this.securities = ['InvestmentFund', 'GovernmentBonds', 'Bond', 'Share', 'CryptoCurrency', 'UnsecuredJuniorDebt'];
        } else {
          this.securities = investorClassification.professionalSecurities;
        }
      } catch (error) {
        this.$notify.error(error);
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    ...mapActions('user', [
      'updateUser',
      'updateOnboarding',
      'setMainStage',
      'setSubStage',
      'updateInvestorClassification',
    ]),
    ...mapActions('settings', [
      'setInvestorClassificationLoading',
    ]),
    async submit() {
      const input = {
        investor: {},
        mainStage: 'classification',
        subStage: 'overview',
      };
      try {
        const nextStage = await this.updateOnboarding({
          input,
        });
        this.setMainStage(nextStage.nextMainStage);
        this.setSubStage(nextStage.nextSubStage);
        this.$notify.success(this.$t('onboarding.classification.investorClassification.notify'));
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    async edit() {
      const input = {
        investor: {
          classification: {
            ...this.classification,
            investorClassification: {
              isProfessionalForAllSecurities: this.classification.investorClassification.isProfessionalForAllSecurities,
              professionalSecurities: this.classification.investorClassification.professionalSecurities,
            },
          },
        },
      };
      this.data.classification = this.classification;
      this.setInvestorClassificationLoading(true);
      try {
        await this.updateInvestorClassification({
          input,
          newData: this.data,
        });
      } catch (error) {
        this.$notify.error(error.message);
      }
      this.setInvestorClassificationLoading(false);
      this.$notify.success(this.$t('settings.knowledgeAndExperience.success'));
      this.$router.push('/settings/knowledge-experience');
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  height: 35px;
}
</style>
